import React from "react";
import couverture from '../../../medias/couverture_article_idees.png';

const Section = ({ title, content }) => (
  <section className="flex flex-col py-2">
    <div>
      <h2 className="text-2xl md:text-3xl pb-4 my-4 lg:text-4xl font-bold text-[#1E2A39] font-yesevaOne">
        {title}
      </h2>
      <p className="text-oceanH2 leading-7 text-justify text-base md:text-l">
        {content}
      </p>
    </div>
  </section>
);

const Content = () => {
  return (
    <div className="w-full">
      <div className="relative h-[300px] md:h-[400px] lg:h-[500px] bg-gray-800">
        <img
          src={couverture}
          alt="Couverture"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-3xl text-center md:text-5xl lg:text-6xl font-bold text-white font-yesevaOne">
            Multipotentiels : Comment prioriser les idées quand elles fusent
          </h1>
        </div>
      </div>

      <div className="md:px-[20%] md:py-[4rem] px-[10%] py-[2rem]">
        <Section
          title="Introduction"
          content="Avoir des idées plein la tête, c’est un peu comme être un enfant dans un magasin de bonbons : tout a l’air délicieux, tout attire notre attention… et on ne sait pas par où commencer ! C’est exactement ce que vivent de nombreux multipotentiels, pour qui chaque nouvelle activité semble fascinante et pour qui chaque sujet mérite d’être exploré. L’enthousiasme est alors sans limite et se déclenche des avalanches d’idées. Mais comment s’assurer de ne pas se disperser et de réussir à concrétiser ses projets ? Il faut savoir faire des choix ! Dans cet article, je vous propose donc ma méthode pour prioriser vos idées, avancer avec clarté et sans frustration."
        />
        <Section
          title="Prioriser selon vos objectifs à long terme"
          content="Première étape pour éviter l’éparpillement : se reconnecter à ce qui compte vraiment pour vous sur le long terme. Vos idées doivent servir un but qui vous tient à cœur, et pas seulement être un enchaînement d’envies passagères. Par exemple, si dans vos objectifs principaux vous souhaitez devenir photographe et paysagiste, toutes vos idées ne vont pas nécessairement s’y prêter."
        />
        <div
          content={
            <>
              <p>
                <b>Exercice pratique :</b> si vous ne l’avez pas encore fait,
                identifiez vos objectifs long, moyen et court terme. Car pas
                d’objectifs, pas de résultats ! Posez-vous alors dans un endroit
                propice à votre concentration et identifiez :
              </p>{" "}
              <br />
              <ul className="list-disc ml-6">
                <li>
                  <strong>Votre objectif long terme (vision ultime) :</strong>{" "}
                  où vous vous voyez dans un futur lointain ? C’est votre
                  destination ultime, votre grande ambition ;
                </li>
                <li>
                  <strong>Vos objectifs moyen terme (trois ans) :</strong>{" "}
                  comment vous positionnez-vous dans les trois prochaines années
                  pour atteindre votre vision ?
                </li>
                <li>
                  <strong>Vos objectifs annuel (prochaines étapes) :</strong>{" "}
                  que pouvez-vous réaliser dans l’année pour vous rapprocher de
                  cet objectif moyen-terme ?
                </li>
                <li>
                  <strong>Vos objectifs mensuel (actions immédiates) :</strong>{" "}
                  quelles sont les actions ou les projets à réaliser ce mois-ci
                  pour avancer vers votre objectif annuel ?
                </li>
                <li>
                  <strong>Votre routine quotidienne (le présent) :</strong>{" "}
                  quelles sont les tâches quotidiennes qui vous mèneront vers
                  votre objectif mensuel ?
                </li>
              </ul>
              <br />
              <p>
                L’important est de concentrer sur une chose à la fois. Et une
                fois que vous avez fait cet exercice vous pourrez plus
                facilement identifier si vos avalanches d’idées vont dans le
                sens de votre vision ultime. 🙂{" "}
              </p>
              <br />
              <p>
                {" "}
                P.s : si vous avez du mal à structurer vos objectifs, vous
                pouvez dans un premier temps utiliser la méthode des “Big
                Rocks”, la matrice d’Eisenhower ou la méthode Moscow.
              </p>
            </>
          }
        />

        <Section
          title="Évaluer le potentiel de chaque idée : questionner et filtrer"
          content={
            <>
              <p>
                Après avoir trié les idées qui correspondent à votre vision
                ultime, il est temps d’évaluer le potentiel de chacune d’elles
                pour y voir encore plus clair ! Toutes les idées ne se valent
                pas, et certaines auront un impact plus significatif sur votre
                parcours.
              </p>
              <p className="mt-4 ">
                <b>Exercice pratique : </b> voici quelques questions pour
                affiner votre choix :
              </p>
              <ul className="list-disc ml-6 mt-2">
                <li>
                  Quelles idées pourraient apporter une réelle valeur ajoutée à
                  ma vie, que ce soit sur le plan personnel ou professionnel ?
                </li>
                <li>
                  Quelles idées semblent moins alignées avec mes compétences
                  uniques et mes valeurs profondes ?
                </li>
                <li>
                  Quelles idées montrent le moins de potentiel de croissance ou
                  de développement ?
                </li>
                <li>
                  Quelles idées risquent de demander beaucoup de temps sans
                  apporter des résultats significatifs en retour ?
                </li>
                <li>
                  Quelles idées me font procrastiner sur un objectif beaucoup
                  plus essentiel ?
                </li>
              </ul>
              <p className="mt-4">
                Ces questions vous permettront de prendre du recul et de
                concentrer votre énergie sur les idées les plus prometteuses !
              </p>
            </>
          }
        />

        <Section
          title="Expérimenter avec des projets pilotes"
          content={
            <>
              <p>
                Avant de vous engager pleinement, pourquoi ne pas tester
                certaines idées en mode « projet pilote » ? Cela vous permet de
                goûter un peu à chaque idée que vous avez priorisé sans pour
                autant y investir toutes vos ressources.
              </p>
              <p className="mt-4 font-bold">Exercice pratique :</p>
              <p className="mt-2">
                Lancez un mini-projet en identifiant une version simplifiée de
                votre idée. Par exemple, si vous voulez écrire un livre,
                commencez par un article de blog sur le même sujet. Puis,
                fixez-vous une durée d’essai, comme un mois ou un trimestre. À
                la fin de cette période, évaluez si cette idée mérite d’être
                développée davantage.
              </p>
            </>
          }
        />
        <Section
          title="Apprendre à renoncer et prioriser avec souplesse"
          content={
            <>
              <p>
                Parfois, pour avancer, il est nécessaire de mettre certaines
                idées de côté, même si elles sont toutes passionnantes. Cela ne
                signifie pas les abandonner définitivement, mais plutôt faire
                preuve de réalisme par rapport au temps et à l’énergie dont vous
                disposez. Il faut alors :
              </p>
              <ul className="list-disc ml-6 mt-4">
                <li>
                  <strong>Accepter de ne pas tout faire :</strong> la clé, c’est
                  le lâcher-prise. Prioriser, c’est avant tout accepter de faire
                  des choix pour avancer sereinement. Gardez en tête que chaque
                  projet n’a pas besoin d’être mené en même temps pour être
                  enrichissant.
                </li>
                <br />
                <li>
                  <strong>Faire le point régulièrement :</strong> une fois par
                  mois ou par trimestre, prenez un moment pour revoir vos
                  priorités. Avec le temps, certaines idées peuvent perdre de
                  leur attrait ou ne plus correspondre à vos aspirations. Pensez
                  à programmer un rappel dans votre agenda pour ce bilan.
                </li>
                <br />
                <li>
                  <strong>Créer une “boîte à idées” :</strong> conservez un
                  espace, qu’il soit numérique ou physique, où vous notez les
                  idées à revisiter plus tard. Cette « boîte à idées » peut
                  devenir une source d’inspiration future ; n’hésitez pas à la
                  consulter régulièrement, en gardant à l’esprit que, là encore,
                  il faudra prioriser.
                </li>
                <br />
              </ul>
            </>
          }
        />

        <Section
          title="Écouter votre intuition"
          content={
            <>
              <p>
                La logique et les méthodes de priorisation sont utiles, mais
                votre intuition est un guide précieux, surtout pour les
                multipotentiels. Faites confiance à votre ressenti pour affiner
                vos choix ! Si une idée fait battre votre cœur, elle mérite
                qu’on lui accorde de l’attention.
              </p>
              <p className="mt-4 font-bold">Exercice pratique :</p>
              <p className="mt-2">
                Imaginez-vous déjà en train de réaliser cette idée. Quelles
                émotions ressentez-vous ? Vous voyez-vous heureux, stimulé,
                épanoui ? Si une idée vous inspire et vous motive profondément,
                c’est un signe fort et l’idée mérite d’être priorisée.
              </p>
            </>
          }
        />
        <Section
          title="Obtenir des retours extérieurs"
          content={
            <>
              <p>
                Un regard extérieur peut également faire toute la différence
                pour clarifier vos idées. Parfois, il suffit de les partager
                avec des personnes de confiance — un ami, un membre de votre
                famille ou même un mentor. L’important c’est qu’elles vous
                connaissent bien et, surtout, vous encouragent à grandir et à
                aller de l’avant. Une fois ces alliés identifiés, vous pouvez :
              </p>
              <ul className="list-disc ml-6 mt-4">
                <li>
                  <strong>Demander des retours honnêtes :</strong> sollicitez
                  leurs avis sur la faisabilité de vos idées et leur alignement
                  avec vos objectifs. Un retour honnête est une aide précieuse
                  pour évaluer chaque projet.
                </li>
                <br />
                <li>
                  <strong>Rechercher l’objectivité :</strong> parfois, on est
                  tellement attaché à une idée qu’il devient difficile d’en voir
                  objectivement les contraintes. Un avis extérieur peut faire
                  émerger des opportunités et des écueils que vous n’aviez pas
                  envisagés.
                </li>
              </ul>
              <p className="mt-4 font-bold">Exercice pratique :</p>
              <p className="mt-2">
                Identifiez une personne de confiance et partagez-lui deux ou
                trois idées qui vous tiennent à cœur. Écoutez son avis et notez
                ses observations.
              </p>
            </>
          }
        />
        <Section
          title="Conclusion"
          content={
            <>
              <p>
                Prioriser les idées, c’est un peu comme essayer de dompter un
                feu d’artifice pour les multipotentiels ! Mais rassurez-vous :
                en prenant le temps de questionner, de structurer et
                d’expérimenter, vous pouvez transformer la dispersion en une
                force. Vous allez enfin pouvoir vous concentrer sur ce qui
                compte vraiment et vous engager dans des projets qui résonnent
                profondément en vous.
              </p>
              <p className="mt-4">
                Et souvenez-vous : chaque idée n’a pas besoin de devenir un
                projet aujourd’hui. En adoptant une approche à la fois
                structurée et bienveillante, tout en suivant votre intuition,
                vous avancez avec clarté et sérénité pour concrétiser vos
                projets de vie ! 🌟
              </p>
            </>
          }
        />
      </div>
    </div>
  );
};

export default Content;
