import React from "react";
import firstProgramImage from "../../../medias/succes.jpg";
import thirdProgramImage from "../../../medias/equilibre_passions_carriere.jpg";
import fourthProgramImage from "../../../medias/ampoule.jpg";


function Articles() {


  const programme = [
    {
      title: "Multipotentiels : transformer votre polyvalence en succès entrepreneurial",
      text: "En tant que multipotentiels, avec des talents et intérêts multiples, vous devez souvent vous heurter à certains dilemmes professionnels...",
      imageUrl: firstProgramImage,
      button: "Découvrir l’article",
      link: '/transformer_votre_polyvalence_en_succes_entrepreneurial',
    },

    {
      title: "Multipotentiels : comment équilibrer vos passions et votre carrière ?",
      text: "Vous êtes du genre à jongler entre une multitude de centres d’intérêt, à sauter d’une passion à l’autre avec enthousiasme, ...",
      imageUrl: thirdProgramImage,
      button: "Découvrir l’article",
      link: '/comment_equilibrer_vos_passions_et_votre_carriere',
    },
    {
      title: "Multipotentiels : Comment prioriser les idées quand elles fusent",
      text: "Vous avez mille idées en tête et ne savez pas par où commencer ? Cet article vous guide pas à pas pour transformer vos idées en projets concrets sans frustration...",
      imageUrl: fourthProgramImage,
      button: "Découvrir l’article",
      link: '/prioriser_idees',
    }
  ];

  return (
    <div className="w-full bg-[#ffefdf] md:mt-12 py-[5rem] flex flex-col items-center">
      {/* Titre dynamique */}
      <h3 className="text-oceanH1 font-yesevaOne text-3xl md:text-4xl mb-8">
        Vous aimerez également ...
      </h3>
      
      {/* Flex layout pour centrer les cartes */}
      <div className="flex flex-col md:flex-row justify-center gap-6 max-w-4xl px-6">
        {programme.map((article, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden min-w-[25rem] max-w-[25rem]">
            <img
              src={article.imageUrl}
              alt=""
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h4 className="font-lato text-oceanH1 text-xl font-bold mb-4">
                {article.title}
              </h4>
              <div className="w-12 border-b-4 border-turquoise mb-4"></div>
              <p className="text-darkBlue text-base mb-6">
                {article.text}
              </p>
              <a
                href={article.link}
                className="inline-block text-dark border-2 border-dark py-2 px-4 rounded-md font-bold hover:bg-dark hover:text-white transition-colors"
              >
                {article.button}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Articles;
