import React from 'react'
import Articles from './Articles';
import Content from './Content';

function Fourth() {
  return (
    <div>
        <Content />
        <Articles />
    </div>
  )
}

export default Fourth