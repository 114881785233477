import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Form from "./components/Form";
import Question from "./components/Form/Question";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Result from "./components/Form/Result";
import ContactMe from "./components/ContactMe";
import TrainingDetails from "./components/TrainingDetails";
import Bonus from "./components/Bonus";
import logo from "../src/medias/logo.png";
import classNames from "classnames";
import Meeting from "./components/Form/Meeting";
import Presentation from "./components/Form/Presentation";
import Footer from "./components/Footer";
import Condition from "./components/Condition";
import MentionsLegales from "./components/Mentions";
import PolitiqueConfidentialite from "./components/Politique";
import Certification from "./components/Home/Certification";
import First from "./components/Articles/First/First";

// import Service from "./components/Home/Service";
import Services from "./components/Services";
import Articles from "./components/Articles/Articles";
import Detail from "./components/Detail";
import Bilan from "./components/Bilan";
import JoyCommunity from "./components/JoyCommunity";
import Second from "./components/Articles/Second/Second";
import Third from "./components/Articles/Third/Third";
import Fourth from "./components/Articles/Fourth/Fourth";

function getCurrentPathname() {
  return window.location.pathname;
}

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  const appClasses = classNames("h-[100vh] bg--900", {
    "fade-in": !loading,
  });

  return (
    <div className={appClasses}>
      {loading ? (
        <div className="h-[100vh] animate-spin loading-indicator flex align-center justify-center items-center">
          <img src={logo} alt="" />
        </div>
      ) : (
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/a_propos" element={<About />} />
            <Route path="/formulaire" element={<Form />} />
            <Route path="/resultat" element={<Result />} />
            <Route path="/contactez-moi" element={<ContactMe />} />
            <Route path="/prise_de_rendez-vous" element={<Meeting />} />
            <Route
              path="/details-sur-la-formation"
              element={<TrainingDetails />}
            />
            <Route path="/presentation" element={<Presentation />} />
            <Route path="/questions" element={<Question />} />
            <Route path="/bonus" element={<Bonus />} />
            <Route path="/blog" element={<Articles />} />
            <Route path="/mes_services" element={<Services />} />
            <Route path="/transformer_votre_polyvalence_en_succes_entrepreneurial" element={<First />} />
            <Route path="/vous_etes_un_atout_dans_le_monde_du_travail" element={<Second />} />
            <Route path="/comment_equilibrer_vos_passions_et_votre_carriere" element={<Third />} />
            <Route path="/prioriser_idees" element={<Fourth />} />
            <Route path="/multi_adventure" element={<Detail />} />
            <Route path="/bilan_de_competences" element={<Bilan />} />
            <Route path="/joy_community" element={<JoyCommunity />} />
            <Route path='/certification' element={<Certification />} />
            <Route path='/conditions_generales' element={<Condition />} />
            <Route path='/mentions_legales' element={<MentionsLegales />} />
            <Route path='/politique_de_confidentialite' element={<PolitiqueConfidentialite />} />
          </Routes>
          {getCurrentPathname() !== '/questions' && getCurrentPathname() !== '/presentation' && <Footer />}
        </Router>
      )}
    </div>
  );
}

export default App;
